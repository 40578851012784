import { useMiddleware } from "@/store/middleware";
import { isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const middleware = useMiddleware();
  const config = useRuntimeConfig();
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if (!isSpecialSubdomain(subdomain)) {
    return navigateTo(`${config.public.primaryURL}/login`, {
      external: true,
    });
  }

  if (!to.query.email && !to.query.token) {
    return navigateTo("/login");
  }

  try {
    await middleware.checkToken({
      email: to.query.email?.toString() ?? "",
      token: to.query.token?.toString() ?? "",
    });
  } catch (e) {
    console.log(e);
  }

  if (middleware.isTokenSuccess) {
    return;
  } else {
    return navigateTo("/login");
  }
});
